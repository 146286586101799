import { Loader } from '@googlemaps/js-api-loader'

window.understanding_glasgow ||= {}

understanding_glasgow.profile_map =
  apiKey: "AIzaSyCDnTydcMPUQigSU_WwxEHUKY3CPf4Zjt0"
  mapElement: document.querySelector('.profile_map_block_element')
  sectors: document.querySelectorAll('.profile_map_block_sector')
  neighbourhoods: document.querySelectorAll('.profile_map_block_neighbourhood')
  sectorPolygons: []
  neighbourhoodPolygons: []
  infoWindow: null

  setup: ->
    return unless @mapElement?

    loader = new Loader
      apiKey: @apiKey
      version: "weekly"

    loader.load()
      .then (google) => @initialize()
      .catch (e) -> console.log(e)

    @addListeners()

  addListeners: ->
    document.querySelectorAll('.profile_map_block_switcher a')?.forEach (link) =>
      link.addEventListener 'click', (e) =>
        e.preventDefault()
        @switchPolygons(e.target)

  initialize: ->
    @map = @createMap(@mapElement)
    bounds = new google.maps.LatLngBounds()
    @infoWindow = new google.maps.InfoWindow

    @addPolygons(@sectors, @map, bounds, @sectorPolygons)
    @addPolygons(@neighbourhoods, @map, bounds, @neighbourhoodPolygons)

    @map.fitBounds(bounds)

  addPolygons: (areas, map, bounds, polygonArray) ->
    return unless areas.length

    areas.forEach (area) =>
      polygonData = JSON.parse area.getAttribute('data-polygon')
      polygon = new google.maps.Polygon
        paths: polygonData
        strokeColor: "#3c83f3"
        strokeOpacity: 0.8
        strokeWeight: 2
        fillColor: "#3c83f3"
        fillOpacity: 0.35
        visible: !area.classList.contains('disabled')

      polygon.setMap(map)
      polygonArray.push(polygon)

      google.maps.event.addListener polygon, 'mouseover', ->
        polygon.setOptions fillColor: '#67c8ca'

      google.maps.event.addListener polygon, 'mouseout', ->
        polygon.setOptions fillColor: '#3c83f3'

      google.maps.event.addListener polygon, 'click', (event) =>
        contentString = area.innerHTML
        @infoWindow.setContent contentString
        @infoWindow.setPosition event.latLng
        @infoWindow.open(map)

      polygon.getPath().forEach (latLng) ->
        bounds.extend(latLng)

  switchPolygons: (link) =>
    document.querySelectorAll('.profile_map_block_switcher a').forEach (switcher) ->
      switcher.classList.remove('active')
    link.classList.add('active')
    understanding_glasgow.profile_map.infoWindow.close()

    sectorVisible = link.classList.contains('sector_switch')

    understanding_glasgow.profile_map.sectorPolygons.forEach (polygon) -> polygon.setVisible(sectorVisible)
    understanding_glasgow.profile_map.neighbourhoodPolygons.forEach (polygon) -> polygon.setVisible(!sectorVisible)

  createMap: (location) ->
    options =
      center: new google.maps.LatLng(-34.397, 50.644)
      mapTypeId: google.maps.MapTypeId.ROADMAP
      maxZoom: 18
      zoom: 8
      draggable: true
      cameraControl: false
      zoomControl: false
      mapTypeControl: false
      streetViewControl: false
      fullscreenControl: false

    new google.maps.Map location, options
